import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { ArrowRight } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import moment from "moment";
import { getNewsDeail } from "@/http/news";
import { useRoute, useRouter } from "vue-router";
import { basicMixin } from "@/mixin/basicMixin";
export default defineComponent({
  mixins: [basicMixin],

  setup() {
    const route = useRoute();
    const router = useRouter();

    const marked = require("marked");

    const data = reactive({
      // 新闻详情数据
      newsDeatil: {}
    });
    const methods = {
      back() {
        router.back();
      },

      getNewsDeailMethod() {
        getNewsDeail({
          code: route.query.id,
          orgCode: sessionStorage.getItem("orgCode") || ""
        }).then(res => {
          if (res.data.code == 200) {
            data.newsDeatil = res.data.data;
          } else {
            ElMessage.error(res.statusText);
          }
        });
      }

    };
    onMounted(() => {
      methods.getNewsDeailMethod();
    }); // onBeforeRouteLeave( (to, from,next) => {
    //   to.meta.keepAlive = true
    //   console.log(to);
    //   next()
    // })

    return {
      ArrowRight,
      ...toRefs(data),
      moment,
      ...methods
    };
  }

});